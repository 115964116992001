.logo {
  height: auto;
  width: auto;
  max-height: 35px;
  min-height: 15px;
  max-width: 30%;
}

.page-header {
  padding: 1rem 0;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 520px) {
  .logo {
    margin-y: 1rem;
  }
}

@media only screen and (min-width: 600px) {
  .page-header {
    padding: 2rem 0;
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .page-padding {
    display: none;
  }
}

/* Hide all filters by default to display them at correct screen sizes */
#filter-container--xs-screens,
#filter-container--m-screens,
#filter-container--l-screens {
  display: none;
}
/* SMALL SCREENS */
@media only screen and (max-width: 800px) {
  #filter-container--xs-screens {
    display: block;
  }
}
/* MEDIUM SCREENS */
@media only screen and (min-width: 801px) and (max-width: 1100px) {
  #filter-container--m-screens {
    display: block;
  }
}
/* LARGE SCREENS */
@media only screen and (min-width: 1101px) {
  #filter-container--l-screens {
    display: block;
    padding-left: 1.5rem;
  }
}

.content-width-guard {
  max-width: 1000px;
}

.hs-search-highlight {
  font-weight: bold;
  display: contents;
}

.filter-button--active-filters {
  box-shadow: inset 0 0 0 2px var(--cds-button-primary-border-color);
  position: relative;
}
.filter-button--active-filters:after {
  content: "";
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 10rem;
  position: absolute;
  top: -0.2rem;
  right: -0.2rem;
  background-color: var(--cds-color-green-lighter20);
  border: 2px solid var(--cds-button-primary-border-color);
}

ol {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

blockquote,
q {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: var(--cds-font-body-size-large);
  vertical-align: baseline;
  background: transparent;
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

.cds-pagination__list {
  flex-wrap: wrap;
}

.cds-pagination--invisible {
  display: none;
}
